import React from "react";
// Customizable Area Start
import { styled, ThemeProvider } from "@material-ui/core/styles";
import FriendsDetailsController, {
    configJSON,
    ICloseFriendsAttributes,
    IFriendMemoriesAttributes,
    IGroupMemoriesAttributes,
    Props,
} from "./FriendsDetailsController.web";
import { 
  Box, 
  Grid, 
  Typography, 
  Button,
  FormLabel,
  RadioGroup, 
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import WebFooter from "../../../components/src/Footer.web";
import WebHeader from "../../../blocks/landingpage/src/WebHeader.web";
import AdditionalInformationSidebar from "../../../components/src/AdditionalInformationSidebar.web";
import { Field, FieldArray, Form, Formik, FormikValues } from "formik";
import { theme, StyledRadio } from "./LegalInformation.web";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CustomInput from "../../../components/src/CustomInput";
import ErrorMessage from "../../../components/src/ErrorMessage";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import MediaUpload from "../../../components/src/MediaUpload.web";
import MyLocationTwoToneIcon from '@material-ui/icons/MyLocationTwoTone';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import CustomDatePicker from "../../../components/src/CustomDatePicker";

const styledMyLocationTwoToneIcon = styled(MyLocationTwoToneIcon)({
    fill: "#A190C2",
});

const StyledAvTimerIcon = styled(AvTimerIcon)({
    fill: "#A190C2",
});
// Customizable Area End

export default class FriendsDetails extends FriendsDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFriendsMemoriesForm = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
      <FieldArray name="friend_memories_attributes" data-test-id="friendMemoriesFieldArray">
        {({ remove, push }) => (
          values.friend_memories_attributes.map((friendMemoriesAttributes:IFriendMemoriesAttributes, index: number) => {
            return (
                <Grid item xs={12} key={index} className="formSectionBackground">
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="fieldArrayHeaderWrapper">
                            <Box>
                                <Typography data-test-id="friendMemoriesFormTitle" variant="h6">
                                   {configJSON.favouriteMemoryFormTitle}
                                </Typography>
                                <Typography data-test-id="friendMemoriesSubtitle" className="memoriesSubtitle">
                                    {configJSON.memoriesSubtitle}
                                </Typography>
                            </Box>
                            {(index === 0 && values.friend_memories_attributes.length < 3) && (
                            <Button 
                            className="addAnotherBtn"
                            data-test-id = "addAnotherFriendMemoryBtn"
                            onClick={() => push({
                                title: "",
                                location: "",
                                time_stamp: "",
                                files: [],
                            })}
                            >
                            <AddCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.addAnotherBtnText}
                            </Button>
                            )}
                            {index !== 0 && (
                            <Button 
                            data-test-id="removeAnotherMemoryBtn"
                            className="addAnotherBtn"
                            onClick={() => remove(index)}>
                            <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.removeBtnText}
                            </Button>)}
                        </Grid>
                        <Grid item lg={4}>
                            <FormLabel component="label">{configJSON.memoryTitleFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                            <Field
                                data-test-id="memoryTitle"
                                name="memoryTitle"
                                className="inputField"
                                size="small"
                                as={CustomInput}
                                placeholder="Enter here"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`friend_memories_attributes.${index}.title`, e.target.value)
                                }}
                                value={friendMemoriesAttributes.title}
                            />
                            <ErrorMessage name={`friend_memories_attributes.${index}.title`} />
                        </Grid>
                        <Grid item lg={4}>
                            <FormLabel component="label">{configJSON.friendMemoryLocationFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                            <Field
                                data-test-id="memoryLocation"
                                name="memoryLocation"
                                className="inputField"
                                size="small"
                                as={CustomInput}
                                placeholder="Enter a Location"
                                startIcon = {styledMyLocationTwoToneIcon}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`friend_memories_attributes.${index}.loction`, e.target.value)
                                }}
                                value={friendMemoriesAttributes.loction}
                            />
                            <ErrorMessage name={`friend_memories_attributes.${index}.loction`} />
                        </Grid>
                        <Grid item lg={4}>
                            <FormLabel component="label">{configJSON.friendMemoryTimeStampFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                            <Field
                                data-test-id="memoryTimeStamp"
                                name="memoryTimeStamp"
                                className="inputField"
                                size="small"
                                as={CustomInput}
                                placeholder="00:00:00"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`friend_memories_attributes.${index}.time_stamp`, e.target.value)
                                }}
                                startIcon={StyledAvTimerIcon}
                                value={friendMemoriesAttributes.time_stamp}
                            />
                            <ErrorMessage name={`friend_memories_attributes.${index}.time_stamp`} />
                        </Grid>
                        <Grid item lg={12}>
                            <MediaUpload
                                data-test-id="mediaUploadForFriendMemories"
                                files={(this.state.filesForFriendMemories.length > 0  && this.state.filesForFriendMemories[index]) ? this.state.filesForFriendMemories[index] : friendMemoriesAttributes.files}
                                onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "friend_memories_attributes", index)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            );
        })
        )}
      </FieldArray>
  )};

  renderCloseFriendMemories = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    },
    closeFriendsAttributes: ICloseFriendsAttributes,
    index: number) => {
        return(
            <>
                <Grid item xs={12} className="fieldArrayHeaderWrapper">
                    <Box>
                        <Typography data-test-id="friendMemoriesFormTitle" variant="h6">
                            {configJSON.favouriteMemoryFormTitle}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.memoryTitleFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="memoryTitle"
                        name="memoryTitle"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Enter a title for memory"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(`close_friends_attributes.${index}.friend_memories_attributes.title`, e.target.value)
                        }}
                        value={closeFriendsAttributes.friend_memories_attributes.title}
                    />
                    <ErrorMessage name={`close_friends_attributes.${index}.friend_memories_attributes.title`} />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.friendMemoryLocationFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="memoryLocation"
                        name="memoryLocation"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="Enter a Location"
                        startIcon={styledMyLocationTwoToneIcon}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(`close_friends_attributes.${index}.friend_memories_attributes.loction`, e.target.value)
                        }}
                        value={closeFriendsAttributes.friend_memories_attributes.loction}
                    />
                    <ErrorMessage name={`close_friends_attributes.${index}.friend_memories_attributes.loction`} />
                </Grid>
                <Grid item lg={4}>
                    <FormLabel component="label">{configJSON.friendMemoryTimeStampFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Field
                        data-test-id="memoryTimeStamp"
                        name="memoryTimeStamp"
                        className="inputField"
                        size="small"
                        as={CustomInput}
                        placeholder="00:00:00"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(`close_friends_attributes.${index}.friend_memories_attributes.time_stamp`, e.target.value)
                        }}
                        startIcon={StyledAvTimerIcon}
                        value={closeFriendsAttributes.friend_memories_attributes.time_stamp}
                    />
                    <ErrorMessage name={`close_friends_attributes.${index}.friend_memories_attributes.time_stamp`} />
                </Grid>
                <Grid item lg={12}>
                    <MediaUpload
                        data-test-id="mediaUploadForCloseFriendMemories"
                        files={(this.state.filesForCloseFriendsMemories.length > 0 && this.state.filesForCloseFriendsMemories[index]) ? this.state.filesForCloseFriendsMemories[index] : closeFriendsAttributes.friend_memories_attributes.files}
                        onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "close_friend_memories_attributes", index)}
                    />
                </Grid>
            </>
        );
    };

  renderClosestFriendsForm = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
      <FieldArray name="close_friends_attributes" data-test-id="closeFriendsFieldArray">
        {({ remove, push }) => (
          values.close_friends_attributes.map((closeFriendsAttributes:ICloseFriendsAttributes, index: number) => {
            return (
                <Grid item xs={12} key={index} className="formSectionBackground">
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="fieldArrayHeaderWrapper">
                            <Box>
                                <Typography data-test-id="closeFriendsFormTitle" variant="h6">
                                    {configJSON.closestFriendsFormTitle}
                                </Typography>
                                <Typography data-test-id="closeFriendsFormSubtitle" className="memoriesSubtitle">
                                    {configJSON.closestFriendsSubtitle}
                                </Typography>
                            </Box>
                            {(index === 0 && values.close_friends_attributes.length < 3) && (
                            <Button 
                            className="addAnotherBtn"
                            data-test-id = "addAnotherClosestFriendBtn"
                            onClick={() => push({
                                full_name: "",
                                about: "",
                                location: "",
                                friend_media_attributes: {
                                    image_location: "",
                                    image_date: "",
                                    files: [],
                                },
                                friend_memories_attributes: {
                                    title: "",
                                    location: "",
                                    time_stamp: "",
                                    files: [],
                                }
                            })}
                            >
                            <AddCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.addAnotherBtnText}
                            </Button>
                            )}
                            {index !== 0 && (
                            <Button 
                            data-test-id="removeAnotherMemoryBtn"
                            className="addAnotherBtn"
                            onClick={() => remove(index)}>
                            <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.removeBtnText}
                            </Button>)}
                        </Grid>
                        <Grid item lg={4}>
                            <FormLabel component="label">{configJSON.closeFriendFullNameFieldLabel}</FormLabel>
                            <Field
                                data-test-id="closeFriendFullName"
                                name="closeFriendFullName"
                                className="inputField"
                                size="small"
                                as={CustomInput}
                                placeholder="Enter full name"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`close_friends_attributes.${index}.full_name`, e.target.value)
                                }}
                                value={closeFriendsAttributes.full_name}
                            />
                        </Grid>
                        <Grid item lg={8}>
                            <FormLabel component="label">{configJSON.closeFriendLocationFieldLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                            <Field
                                data-test-id="closeFriendLocation"
                                name="closeFriendLocation"
                                size="small"
                                as={CustomInput}
                                placeholder="Location"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`close_friends_attributes.${index}.loction`, e.target.value)
                                }}
                                startIcon={styledMyLocationTwoToneIcon}
                                value={closeFriendsAttributes.loction}
                            />
                            <ErrorMessage name={`close_friends_attributes.${index}.loction`} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="label">{configJSON.closeFriendLocationFieldLabel}</FormLabel>
                            <Field
                                data-test-id="aboutCloseFriend"
                                name="aboutCloseFriend"
                                size="small"
                                multiline
                                as={CustomInput}
                                placeholder="Write about first meeting with your friend"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`close_friends_attributes.${index}.about`, e.target.value)
                                }}
                                value={closeFriendsAttributes.about}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel component="label">{configJSON.closeFriendDescriptionFieldLabel}</FormLabel>
                            <Field
                                data-test-id="closeFriendDescription"
                                name="closeFriendDescription"
                                size="small"
                                multiline
                                as={CustomInput}
                                placeholder="Min 3 words"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(`close_friends_attributes.${index}.description`, e.target.value)
                                }}
                                value={closeFriendsAttributes.description}
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <MediaUpload
                                data-test-id="mediaUploadForCloseFriend"
                                files={(this.state.filesForCloseFriends.length > 0  && this.state.filesForCloseFriends[index]) ? this.state.filesForCloseFriends[index] : closeFriendsAttributes.friend_media_attributes.files}
                                onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "close_friends_attributes")}
                            >
                                <Box className="mediaAttribute">
                                    <FormLabel component="label">{configJSON.friendMediaAttributeLocationLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                    <Field
                                        data-test-id="closeFriendMediaLocation"
                                        name="closeFriendMediaLocation"
                                        className="mediaAttributeInput"
                                        size="small"
                                        as={CustomInput}
                                        placeholder="Enter a Location"
                                        startIcon={styledMyLocationTwoToneIcon}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`close_friends_attributes.${index}.friend_media_attributes.image_location`, e.target.value)
                                        }}
                                        value={closeFriendsAttributes.friend_media_attributes.image_location}
                                    />
                                    <ErrorMessage name={`close_friends_attributes.${index}.friend_media_attributes.image_location`} />
                                </Box>
                                <Box className="mediaAttribute">
                                    <FormLabel component="label">{configJSON.friendMediaAttributeDateLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                    <Field
                                        data-test-id="closeFriendMediaDate"
                                        name="closeFriendMediaDate"
                                        className="mediaAttributeInput"
                                        size="small"
                                        as={CustomDatePicker}
                                        placeholder="Enter a Date"
                                        endIcon={
                                            this.calenderIcon
                                        }
                                        onChange={(date: Date) => 
                                            setFieldValue(`close_friends_attributes.${index}.friend_media_attributes.image_date`, date.toLocaleDateString("en-GB"))
                                        }
                                        value={closeFriendsAttributes.friend_media_attributes?.image_date}
                                        />
                                        <ErrorMessage name={`close_friends_attributes.${index}.friend_media_attributes.image_date`} />
                                </Box>
                            </MediaUpload>
                        </Grid>
                        <Grid item lg={12}>
                              <Box className="closeFriendMemoryWrapper">
                                <Typography align="center" className="memoryWithCloseFriendLabel">
                                  {configJSON.closeFriendMemoriesSwitchLabel}
                                </Typography>
                                <Switch
                                  data-test-id="memoryWithCloseFriendSwitch"
                                  color="primary"
                                  checked={closeFriendsAttributes.add_memory_with_close_friend}
                                  onChange={ () => 
                                    this.handleSwitchChange(index, setFieldValue)
                                  }
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </Box>
                        </Grid>
                        { closeFriendsAttributes.add_memory_with_close_friend && (
                            this.renderCloseFriendMemories(values, setFieldValue, closeFriendsAttributes, index)
                        )}
                    </Grid>
                </Grid>
            );
        })
        )}
      </FieldArray>
  )};

  renderGroupMemoriesForm = (
    values: FormikValues,
    setFieldValue: {
      (field: string,
        value: any,
        shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
    }) => {
    return(
        <FieldArray name="group_memories_attributes" data-test-id="groupMemoriesFieldArray">
        {({ remove, push }) => (
          values.group_memories_attributes.map((groupMemoriesAttributes: IGroupMemoriesAttributes, index: number) => {
            return (
                <Grid item xs={12} key={index} className="formSectionBackground">
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="fieldArrayHeaderWrapper">
                            <Box>
                                <Typography data-test-id="groupMemoriesFormTitle" variant="h6">
                                   {configJSON.groupMemoriesFormTitle}
                                </Typography>
                            </Box>
                            {(index === 0 && values.group_memories_attributes.length < 3) && (
                            <Button 
                            className="addAnotherBtn"
                            data-test-id = "addAnotherGroupMemoryBtn"
                            onClick={() => push({
                                friend_name: [],
                                files: [],
                            })}
                            >
                            <AddCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.addAnotherBtnText}
                            </Button>
                            )}
                            {index !== 0 && (
                            <Button 
                            data-test-id="removeAnotherMemoryBtn"
                            className="addAnotherBtn"
                            onClick={() => remove(index)}>
                            <RemoveCircleOutlineIcon className="circleOutlineIcon" />
                                {configJSON.removeBtnText}
                            </Button>)}
                        </Grid>
                        { this.renderFriendNameFields(values, setFieldValue, groupMemoriesAttributes, index)}
                        <Grid item lg={12}>
                            <MediaUpload
                                data-test-id="mediaUploadForGroupMemories"
                                files={(this.state.filesForGroupMemories.length > 0  && this.state.filesForGroupMemories[index]) ? this.state.filesForGroupMemories[index] : groupMemoriesAttributes.files}
                                onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "group_memories_attributes", index)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            );
        })
        )}
      </FieldArray>
    )};

    renderFriendNameFields = (
        values: FormikValues,
        setFieldValue: {
          (field: string,
            value: any,
            shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        },
        groupMemoriesAttributes: IGroupMemoriesAttributes,
        groupIndex: number) => {
        return(
            <FieldArray name={`group_memories_attributes.${groupIndex}.friend_name`} data-test-id="groupFriendNameFieldArray">
                {({ push }) => (
                    <>
                    {values.group_memories_attributes[groupIndex].friend_name.map((friendName: string, index: number) => {
                        return (
                            <div key={index}>
                                <Grid item lg={4}>
                                    <FormLabel component="label">{configJSON.friendNameFieldLabel}</FormLabel>
                                    <Field
                                        data-test-id="groupFriendName"
                                        name="groupFriendName"
                                        className="inputField"
                                        size="small"
                                        as={CustomInput}
                                        placeholder="Enter friend name"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`group_memories_attributes.${groupIndex}.friend_name.${index}`, e.target.value)
                                        }}
                                        value={friendName}
                                    />
                                </Grid>
                                {index === groupMemoriesAttributes.friend_name.length - 1 && (
                                <Grid item xs={4} className="fieldArrayHeaderWrapper">
                                        <Button
                                            className="addAnotherBtn"
                                            data-test-id="addMoreFriendNameBtn"
                                            onClick={() => push("")}
                                        >
                                            <AddCircleOutlineIcon className="circleOutlineIcon" />
                                            {configJSON.addMoreBtnText}
                                        </Button>
                                </Grid>
                                )}
                            </div> 
                        );
                    })}
                    </>
                )}
            </FieldArray>
      )};
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { 
        friendsDetails,
        showFriendsMemory,
        showClosestFriend,
        showGroupMemory,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <WebHeader navigation={this.props.navigation} progress={45}/>
          <Box className="containerBox">
            <Box className="sidebarWrapper">
                <AdditionalInformationSidebar handleNavigation={this.handleNavigation} />
            </Box>
            <Box className="friendsDetailsWrapper">
                <Box className="pageTilteWrapper">
                    <Typography data-test-id="pageTilteWrapper" variant="h3" className="titleText">
                        {configJSON.friendsDetailsTitle}
                    </Typography>
                    <Typography variant="h3" className="messageText">
                        <InfoOutlinedIcon className="infoIcon"/>
                        <span>{configJSON.messageText}</span>
                    </Typography>
                </Box>
                <Box className="formWrapperBox">
                <Formik
                    initialValues={friendsDetails}
                    validationSchema={this.validationSchema}
                    onSubmit={(values) => {
                      this.handleFriendsDetailsFormSubmit(values)
                    }}
                    enableReinitialize={true}
                    data-test-id="friendsDetailsForm"
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                      <Box className="formWrapperBox">
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.friendsDetailsQuestion1}
                                    </FormLabel>
                                    <Field
                                        data-test-id="friendName"
                                        className="inputField multilineInput"
                                        name="friendName"
                                        as={CustomInput}
                                        placeholder="Enter full name"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`friend_name`, e.target.value)
                                        }}
                                        value={values.friend_name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.friendsDetailsQuestion2}
                                    </FormLabel>
                                    <Field
                                        data-test-id="friendSummary"
                                        name="friendSummary"
                                        className="multilineInput"
                                        as={CustomInput}
                                        multiline
                                        placeholder="Write about first meeting with your best friend"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`friend_summary`, e.target.value)
                                        }}
                                        value={values.friend_summary}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel multilineInput">
                                        {configJSON.locationFieldLabel}
                                        <span style={{ color: "red" }}>*</span>
                                    </FormLabel>
                                    <Field
                                        data-test-id="location"
                                        name="location"
                                        className="inputField multilineInput"
                                        as={CustomInput}
                                        startIcon={styledMyLocationTwoToneIcon}
                                        placeholder="Location"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`location`, e.target.value)
                                        }}
                                        value={values.location}
                                        error={touched.location && errors.location}
                                        errorMsg={errors.location}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.friendsDetailsQuestion3}
                                    </FormLabel>
                                    <Field
                                        data-test-id="description"
                                        name="description"
                                        className="multilineInput"
                                        as={CustomInput}
                                        multiline
                                        placeholder="Min 3 words"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(`description`, e.target.value)
                                        }}
                                        value={values.description}
                                    />
                                    <Box ml={2}>
                                        <MediaUpload
                                            data-test-id="mediaUploadForFriend"
                                            files={this.state.filesForFriendMedia.length > 0 ? this.state.filesForFriendMedia : friendsDetails.friend_media_attributes.files}
                                            onUpload={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileUpload(event, setFieldValue, "friend_media_attributes")}
                                        >
                                            <Box className="mediaAttribute">
                                            <FormLabel component="label">{configJSON.friendMediaAttributeLocationLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                            <Field
                                                data-test-id="friendMediaLocation"
                                                name="friendMediaLocation"
                                                className="mediaAttributeInput"
                                                size="small"
                                                as={CustomInput}
                                                placeholder="Enter a Location"
                                                startIcon={styledMyLocationTwoToneIcon}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setFieldValue(`friend_media_attributes.image_location`, e.target.value)
                                                }}
                                                value={values.friend_media_attributes.image_location}
                                                error={touched.friend_media_attributes?.image_location && errors.friend_media_attributes?.image_location}
                                                errorMsg={errors.friend_media_attributes?.image_location}
                                            />
                                            </Box>
                                            <Box className="mediaAttribute">
                                                <FormLabel component="label">{configJSON.friendMediaAttributeDateLabel}<span style={{ color: "red" }}>*</span></FormLabel>
                                                <Field
                                                    data-test-id="friendMediaDate"
                                                    name="friendMedia"
                                                    className="mediaAttributeInput"
                                                    size="small"
                                                    as={CustomDatePicker}
                                                    placeholder="Enter a Date"
                                                    endIcon={
                                                    this.calenderIcon
                                                    }
                                                    onChange={(date: Date) => 
                                                    setFieldValue("friend_media_attributes.image_date", date.toLocaleDateString("en-GB"))
                                                    }
                                                    value={values.friend_media_attributes?.image_date}
                                                    error={touched.friend_media_attributes?.image_date && errors.friend_media_attributes?.image_date}
                                                    errorMsg={errors.friend_media_attributes?.image_date}
                                                />
                                            </Box>
                                        </MediaUpload>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.friendsDetailsQuestion4}
                                    </FormLabel>
                                    <RadioGroup
                                        className="radioGroup"
                                        data-test-id="haveFavouriteMemory"
                                        aria-label="haveFavouriteMemory"
                                        name="haveFavouriteMemory"
                                        value={values.have_favourite_memory}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue("have_favourite_memory", e.target.value);
                                            this.setState({ showFriendsMemory: !showFriendsMemory });
                                        }}
                                    >
                                        <FormControlLabel
                                        className="radioLabel"
                                        value="1"
                                        control={<StyledRadio />}
                                        label="Yes"
                                        />
                                        <FormControlLabel
                                        className="radioLabel"
                                        value="0"
                                        control={<StyledRadio />}
                                        label="No"
                                        />
                                    </RadioGroup>
                                </Grid>
                                { showFriendsMemory && (
                                    this.renderFriendsMemoriesForm(values, setFieldValue)
                                )}
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.friendsDetailsQuestion5}
                                    </FormLabel>
                                    <RadioGroup
                                        className="radioGroup"
                                        data-test-id="haveClosestFriend"
                                        aria-label="haveClosestFriend"
                                        name="haveClosestFriend"
                                        value={values.have_closest_friend}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("have_closest_friend", e.target.value);
                                        this.setState({ showClosestFriend: !showClosestFriend });
                                        }
                                        }
                                    >
                                        <FormControlLabel
                                        className="radioLabel"
                                        value="1"
                                        control={<StyledRadio />}
                                        label="Yes"
                                        />
                                        <FormControlLabel
                                        className="radioLabel"
                                        value="0"
                                        control={<StyledRadio />}
                                        label="No"
                                        />
                                    </RadioGroup>
                                </Grid>
                                { showClosestFriend && (
                                    this.renderClosestFriendsForm(values, setFieldValue)
                                )}
                                <Grid item xs={12}>
                                    <FormLabel component="label" className="questionLabel">
                                        {configJSON.friendsDetailsQuestion6}
                                    </FormLabel>
                                    <RadioGroup
                                        className="radioGroup"
                                        data-test-id="haveGroupMemory"
                                        aria-label="haveGroupMemory"
                                        name="haveGroupMemory"
                                        value={values.have_group_memory}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("have_group_memory", e.target.value);
                                        this.setState({ showGroupMemory: !showGroupMemory });
                                        }
                                        }
                                    >
                                        <FormControlLabel
                                        className="radioLabel"
                                        value="1"
                                        control={<StyledRadio />}
                                        label="Yes"
                                        />
                                        <FormControlLabel
                                        className="radioLabel"
                                        value="0"
                                        control={<StyledRadio />}
                                        label="No"
                                        />
                                    </RadioGroup>
                                </Grid>
                                { showGroupMemory && (
                                    this.renderGroupMemoriesForm(values, setFieldValue)
                                )}
                            </Grid>
                        </Box>
                        <Box className="btnWrapperBox">
                          <Button
                            data-test-id="skipForNow"
                            variant="outlined"
                            className="btnSize"
                            onClick={
                                () => this.handleNavigation("CustomForm")
                            }
                          >
                            {configJSON.skipForNowBtnText}
                          </Button>
                          <Button
                            className="btnSize"
                            type="submit"
                            variant="contained"
                            data-test-id="saveAndNextBtn"
                          >
                            {configJSON.saveAndNext}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                    )}
                    </Formik>
                </Box>
            </Box>
          </Box>
          <WebFooter 
            handleNavigation={this.handleNavigation} 
          />
        </Wrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const Wrapper = styled("div")(({ theme }) => ({
  "& .friendsDetailsWrapper": {
    width: "100%",
  },
  "& .containerBox": {
    display: "flex",
    gap: "72px",
    padding: "50px 72px",
  },
  "& .pageTilteWrapper": {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0",
    alignItems: "center",
  },
  "& .fieldArrayHeaderWrapper": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .memoriesSubtitle": {
    color: "#475569",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  "& .messageText": {
      color: "#9176C4",
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "18px",
      display: "flex",
      alignItems: "center",
      gap: "4px",
  },
  "& .infoIcon": {
    fontSize: "20px",
  },
  "& .formWrapperBox": {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  "& .questionLabel": {
      color: "#1E293B",
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "22px",
  },
  "& .formSectionBackground": {
      borderRadius: "8px",
      padding: "24px",
      backgroundColor: "rgba(206, 195, 229, 0.1)",
  },
  "& .multilineInput": {
    marginLeft: "16px",
  },
  "& .selectField": {
      display: "block",
      width: "300px",
      height: "48px",
      borderRadius: "8px",
      margin: "5px 0px",
  },
  "& .inputField": {
      height: "48px",
      width: "275px",
      borderRadius: "8px",
  },
  "& .mediaAttributeInput": {
    height: "48px",
    maxWidth: "260px",
    borderRadius: "8px",
  },
  "& .radioGroup": {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: "16px",
    gap: "35px",
  },
  "& .radioLabel": {
    color: '#1E293B',
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#9176C4',
    },
  },
  "& .closeFriendMemoryWrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .memoryWithCloseFriendLabel": {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#1E293B", 
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .btnWrapperBox": {
      marginTop: "72px",
      display: "flex",
      gap: "8px",
      justifyContent: "flex-end",
  },
  "& .btnSize": {
      width: "158px",
      height: "48px",
  },
  "& .circleOutlineIcon": {
      fontSize: "16px",
      marginRight: "8px",
  },
  "& .addAnotherBtn": {
    display: "flex",
    color: "#9176C4",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textTransform: "none",
    alignItems: "center",
    '&:hover': {
        textDecoration: "underline",
        backgroundColor: "transparent",
    },
  },
  "& .mediaAttribute": {
    display: "flex",
    flexDirection: "column",
  },
  "& .calenderIconStyle": {
    cursor: "grab",
  },
  [theme.breakpoints.down(740)]: {
    "& .containerBox": {
        padding: "28px 16px",
    },
  },
}));
// Customizable Area End
