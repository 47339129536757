// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Geotaggingforpictures from "../../blocks/geotaggingforpictures/src/Geotaggingforpictures";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Adminconsole from "../../blocks/adminconsole/src/Adminconsole";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomForm from "../../blocks/customform/src/CustomForm.web";
import LegalInformation from "../../blocks/customform/src/LegalInformation.web";
import MedicalInformation from "../../blocks/customform/src/MedicalInformation.web";
import InvestmentsAndFunds from "../../blocks/customform/src/InvestmentsAndFunds.web";
import FamilyDetails from "../../blocks/customform/src/FamilyDetails.web";
import FriendsDetails from "../../blocks/customform/src/FriendsDetails.web";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions.web";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles";
import DelegateMembers from "../../blocks/customisableuserprofiles/src/DelegateMembers.web";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Inapppurchasing1 from "../../blocks/inapppurchasing1/src/Inapppurchasing1";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Signuplogin from "../../blocks/signuplogin/src/Signuplogin";
import Signup from "../../blocks/signuplogin/src/Signup.web";

import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Termsandconditions from "../../blocks/termsandconditions/src/Termsandconditions";
import Privacypolicy from "../../blocks/termsandconditions/src/Privacypolicy";

import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Splashscreen1 from "../../blocks/splashscreen1/src/Splashscreen1";
import Voicenotes from "../../blocks/voicenotes/src/Voicenotes";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Settings from "../../blocks/settings/src/Settings";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";

import ShowProduct from "../../blocks/navigationmenu/src/ShowProduct.web";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu.web";

import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Payments from "../../blocks/payments/src/Payments";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OrderSummary from "../../blocks/ordermanagement/src/OrderSummary.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";




const routeMap = {
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},

Geotaggingforpictures:{
 component:Geotaggingforpictures,
path:"/Geotaggingforpictures"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Adminconsole:{
 component:Adminconsole,
path:"/Adminconsole"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ResetPassword:{
  component:ResetPassword,
 path:"/ResetPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
CustomForm:{
  component:CustomForm,
 path:"/CustomForm"},
LegalInformation:{
  component:LegalInformation,
 path:"/LegalInformation"},
 MedicalInformation:{
  component:MedicalInformation,
 path:"/MedicalInformation"},
InvestmentsAndFunds:{
  component:InvestmentsAndFunds,
 path:"/InvestmentsAndFunds"},
 FamilyDetails:{
  component:FamilyDetails,
 path:"/FamilyDetails"},
 FriendsDetails:{
  component:FriendsDetails,
 path:"/FriendsDetails"},
CustomisableUserProfiles:{
  component:CustomisableUserProfiles,
 path:"/CustomisableUserProfiles"},
 DelegateMembers:{
  component:DelegateMembers,
 path:"/DelegateMembers"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Inapppurchasing1:{
 component:Inapppurchasing1,
path:"/Inapppurchasing1"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Signuplogin:{
 component:Signuplogin,
path:"/Signuplogin"},
Signup:{
  component:Signup,
 path:"/Signup"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Termsandconditions:{
 component:Termsandconditions,
path:"/Termsandconditions"},
Privacypolicy:{
  component:Privacypolicy,
 path:"/Privacypolicy"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Splashscreen1:{
 component:Splashscreen1,
path:"/Splashscreen1"},
Voicenotes:{
 component:Voicenotes,
path:"/Voicenotes"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Settings:{
 component:Settings,
path:"/Settings"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu/:navigationBarTitleText"},
ShowProduct:{
 component:ShowProduct,
path:"/ShowProduct/:navigationBarTitleText",
},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Payments:{
 component:Payments,
path:"/Payments"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OrderSummary:{
  component:OrderSummary,
 path:"/OrderSummary/:navigationBarTitleText"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;