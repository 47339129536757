Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = 
"/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = 
"/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = 
"/bx_block_custom_forms/custom_forms";

exports.getDelegateMembersApiEndPoint = 
"bx_block_customisableuserprofiles/delegate_members";
exports.getLegalInformationApiEndPoint = 
"bx_block_custom_form/legal_informations";

exports.getMedicalInformationApiEndPoint = "/bx_block_custom_form/medical_informations";

exports.getInvestmentsAndFundsApiEndPoint = "/bx_block_custom_form/investment_informations";

exports.getFamilyDetailsApiEndPoint = "/bx_block_custom_form/family_details";

exports.getFriendsDetailsApiEndPoint = "/bx_block_custom_form/friend_details";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";

exports.createFormAPiEndPoint = 
"bx_block_custom_forms/custom_forms";
exports.token=
"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.fetchFormDataMethod="GET";
exports.updateFormAPiMethod="PUT";
exports.formAPiMethod = "POST";
exports.errorPasswordNotValid = "Not valid.";
exports.formApiContentType = "multipart/form-data";
exports.labelHeader = " Custom Form ";
exports.labelOr = "OR";
exports.btnTxtSubmit = "Submit";
exports.placeHolderLastName = "Last Name";
exports.placeHolderFirstName = "First Name";
exports.placeHolderOrg = "Organization";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderEmail = "Email";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderCountry = "Country";
exports.placeHolderAddress = "Address";
exports.placeHolderCity="City";
exports.placeHolderState = "State";

exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;

exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";

exports.legalInformationPageTitle = "Legal Information";
exports.DelegateInChargeLabel = "Delegate In-charge";
exports.haveLegalRepresentativeLabel = 
"1. Do you have a legal representative in place to help with the transition of assets and possessions to loved ones or charities?";
exports.haveWillLabel = 
"2. Do you have a will in place already?";
exports.haveLifeInsuranceLabel = 
"3. Do you have a Life Insurance?";
exports.DIYText = 
"D.I.Y wills can be purchased on the DELEGATE MEE web portal"; 
exports.delegatemeeSiteLinkText = "‘www.delegatemee.com’";

exports.additionalInformationPageTitleText = "Additional Information";
exports.additionalInformationDescriptionText = 
"Before we get started, please add some additional important information about you.";
exports.skipForNowBtnText = "Skip for now";

exports.saveAndNext = "Save & Next";

// dialog texts
exports.infoAddedDialogTitleText = "Information Added";
exports.nextSectionBtnText = "Next Section";

exports.firstNameLabel = "First Name";
exports.middleNameLabel = "Middle Name (optional)";
exports.lastNameLabel = "Last Name";
exports.nationality = "Nationality";
exports.genderLabel = "Gender";
exports.genderNameLabel = "Enter gender";

exports.infoNotAddedTitleText = "Information Not Added";
exports.infoNotAddedDescriptionText = 
"Legal information has not been added to your profile yet. You can always add it whenever you like!";
exports.backBtnText = "Back";

// LegalRepresentativeForm
exports.legalRepresentativeFormTitle = "Legal Representative";
exports.fullNameLabel = "Full Name";
exports.emailIDLabel = "Email ID";
exports.phoneNumberLabel = "Phone Number";

exports.fullNamePlaceholder = "Enter representative's full name";
exports.emailIDPlaceholder = "example@gmail.com";
exports.phoneNumberPlaceholder = "0000000000";

// Kin Details Form
exports.kinDetailsFormTitle = "Kin Details";
exports.relationFieldLabel = "Relation to next to Kin";
exports.dateOfBirthLabel = "Date of Birth";
exports.addressLine1Label = "Address line 1";
exports.addressLine2Label = "Address line 2";
exports.landmarkLabel = "Landmark (if required)";
exports.townCityLabel = "Town/City";
exports.countryLabel = "Country";
exports.postCodeLabel = "Post Code";

exports.kinFullNamePlaceholder = "Enter Kin's full name";
exports.kinEmailIDPlaceholder = "example@gmail.com";
exports.kinPhoneNumberPlaceholder = "0000000000";
exports.kinRelationFieldPlaceholder = "Enter relationship";
exports.kinDateOfBirthPlaceholder= "DD/MM/YYYY";
exports.kinAddressLine1Placeholder = "House/Flat no.";
exports.kinAddressLine2Placeholder = "Street and Locality";
exports.kinLandmarkPlaceholder = "Landmark";
exports.kinTownCityPlaceholder = "City";
exports.kinCountryPlaceholder = "Country";
exports.kinPostCodePlaceholder = "Post Code";


// Location Of Will Form
exports.nameOfPersonHoldingWillPlaceholder = "Enter full name";
exports.additionalInformationPlaceholder = 
"Example - Will is stored in the family safe";
exports.willAddressLine1Placeholder = "House numberFlat no.";
exports.willAddressLine2Placeholder = "Street and Locality";
exports.willLandmarkPlaceholder = "Landmark";
exports.willTownCityPlaceholder = "City";
exports.willCountryPlaceholder = "Country";
exports.willPostCodePlaceholder = "Post Code";

exports.locationOfWillFormTitle = "Location of Will";
exports.nameOfPersonHoldingWillLabel = "Name of person holding will (if any)";
exports.additionalInformationLabel = "Additional Information (optional)";


// Policy Details Form

exports.policyNumberPlaceholder = "Enter policy number";
exports.policyProviderPlaceholder = "Enter full name of provider";
exports.agentNamePlaceholder = "Enter full name of agent";
exports.planNamePlaceholder = "Enter plan name";
exports.policyTermPlaceholder = "Enter policy term";
exports.paymentFrequencyPlaceholder = "Select payment frequency";
exports.startDatePlaceholder = "DD/MM/YYYY";
exports.maturityDatePlaceholder = "DD/MM/YYYY";
exports.sumAssuredPlaceholder = "00.00";


exports.policyDetailsFormTitle = "Policy Details";
exports.policyNumberLabel = "Policy Number";
exports.policyProviderLabel = "Policy Provider (Company)";
exports.agentNameLabel = "Agent Name (if any)";
exports.planNameLabel = "Plan Name";
exports.policyTermLabel = "Policy Term (Years)";
exports.paymentFrequencyLabel = "Payment Frequency";
exports.startDateLabel = "Start Date";
exports.maturityDateLabel = "Maturity Date";
exports.sumAssuredLabel = "Sum Assured";
exports.addMorePolicyBtnText = "Add more policies";
exports.removePolicyBtnText = "Remove Policy";


// validation messages
exports.delegateInchargeMsg = "Please select delegate member";
exports.fullNameMsg = "Please enter your full name";
exports.emailMsg = "Please enter email";
exports.validEmailMsg = "Please enter valid email";
exports.phoneNumberMsg = "Please enter phone number";

exports.kinFullNameMsg = "Please enter kin's full name";
exports.kinRelationMsg = "Please enter relation to next to kin";
exports.kinEmailMsg = "Please kin's enter email";
exports.kinValidEmailMsg = "Please enter kin's valid email";
exports.kinDOBMsg = "Please enter kin's date of birth";
exports.kinAddressline1Msg = "Please enter kin's addressline1";
exports.kinAddressline2Msg = "Please enter kin's addressline2";
exports.kinCityMsg = "Please enter kin's city";
exports.kinCountryMsg = "Please select kin's country";
exports.kinPostcodeMsg = "Please enter a valid post code";

exports.addressline1Msg = "Please enter addressline1";
exports.addressline2Msg = "Please enter addressline2";
exports.cityMsg = "Please enter city";
exports.countryMsg = "Please select country";
exports.postcodeMsg = "Please enter a valid post code";

exports.policyNumberMsg = "Please enter policy number";
exports.policyProviderMsg = "Please enter name of policy provider";
exports.planNameMsg = "Please enter plan name";
exports.policyTermMsg = "Please enter policy term in years";
exports.paymentFrequencyMsg = "Please select payment frequency";
exports.startDateOfPolicyMsg = "Please enter start date of policy";
exports.maturityDateOfPolicyMsg = "Please enter maturity date of policy";
exports.sumAssuredMsg = "Please enter sum assured ";

// Medical Information //
exports.medicalInfoPageTitle = "Medical Information";
exports.pdfLabel = "Include this information in Final PDF";
exports.haveMedicalConditionQuestion = "1. Do you have any medical conditions?";
exports.haveRegisteredOrganDonationQuestion = "2. Have you registered to opt OUT of organ donation if you live in ENGLAND?";
exports.haveYouGiveBloodQuestion = "3. Do you give blood?";
exports.haveYouKnowBloodTypeQuestion = "4. Do you know blood type?";

// Medical Condition Form
exports.medicalConditionFormTitle = "Medical Condition";
exports.addMoreConditionsBtnText = "Add more conditions";
exports.removeConditionBtnText = "Remove condition";

exports.conditionTypeLabel = "Condition Type";
exports.conditionNameLabel = "Condition Name";
exports.levelOfConditionLabel = "Level of Condition";
exports.descriptionFieldLabel = "Description (if required)";

exports.organDonationDetailsFormTitle = "Organ donation Details";
exports.organDonationDetailsFormDescription = "This information can be found on your donation card/confirmation";

exports.idNumberFieldLabel = "ID Number";
exports.organsCheckBoxListLabel = "Organs to be donated:"; 
exports.organNameFieldLabel = "Organ Name";

exports.addMoreBtnText = "Add more";
exports.removeBtnText = "Remove";

exports.bloodGroupFieldLabel = "Blood Group";


// Investments & Funds //
exports.investmentsAndFundsPageTitle = "Investments & Funds";

exports.haveBankingProviderQuestion = "1. Do you have a banking provider?";
exports.haveStocksQuestion = "2. Do you have any Stocks/ Shares?";
exports.havePremimumBondsQuestion = "3. Do you have any premium bonds?";

exports.bankDetailsFormTitle = "Bank Details";
exports.providerNameFieldLabel = "Provider Name";

exports.stockDetailsFormTitle = "Stock Details";
exports.stockNameFieldLabel = "Stock Name";
exports.stockQuantity = "Stock Quantity";
exports.totalValue = "Total Value";

exports.bondDetailsFormTitle = "Bond Details";
exports.holderNameFieldLabel = "Holder’s Name";
exports.unitFieldLabel = "Unit(€)";
exports.noOfUnitFieldLabel = "Number of Units";
exports.bondValueFieldLabel = "Bond Value(€)";

// Family Details //
exports.familyDetailsTitle = "Family Details";
exports.messageText = "This info will be added to your final booklet";

exports.familyDetailsQuestion1 = "1. In your family, who are you closest to?";
exports.familyDetailsQuestion2 = "2. Why is this person special to you?";
exports.familyDetailsQuestion3 = "3. What do you love most about your family?";
exports.familyDetailsQuestion4 = "4. Add your favourite memory of you and your family?";
exports.familyDetailsQuestion5 = "5. Add a festive memory (eg, Christmas)";
exports.familyDetailsQuestion6 = "6. Please describe your family in three words";

exports.memoriesSubtitle = "You can add max 3 memories";
exports.addAnotherBtnText = "Add another";

exports.favouriteMemoryFormTitle = "Memories";
exports.memoryTitleFieldText = "Memory Title";

exports.friendMediaAttributeLocationLabel = "Location of Image";
exports.friendMediaAttributeDateLabel = "Date of Image";

exports.festiveMemoryFormTitle = "Festive Memory";
exports.celebrationTypeFieldText = "Celebration type";

// friends details //
exports.friendsDetailsTitle = "Friends Details";

exports.friendsDetailsQuestion1 = "1. Name of your best friend";
exports.friendsDetailsQuestion2 = "2. How you met your best friend?";
exports.friendsDetailsQuestion3 = "3. Please describe your best friend in three or more words";
exports.friendsDetailsQuestion4 = "4. Add your favourite memory of you and your best friend";
exports.friendsDetailsQuestion5 = "5. Add more of your closest friends?";
exports.friendsDetailsQuestion6 = "6. Add a group memory?";

exports.locationFieldLabel = "Location where you met";

// friend memories attributes
exports.memoryTitleFieldLabel = "Memory Title";
exports.friendMemoryLocationFieldLabel = "Location";
exports.friendMemoryTimeStampFieldLabel = "Time Stamp";

// closest friends form
exports.closestFriendsFormTitle = "Close Friend";
exports.closestFriendsSubtitle = "You can add max 3 close friends";

exports.closeFriendFullNameFieldLabel = "Full name";
exports.closeFriendLocationFieldLabel = "Location where you met";
exports.aboutCloseFriendFieldLabel = "Write about first meeting with your friend";
exports.closeFriendDescriptionFieldLabel = 
"Please describe your close friend in three or more words";
exports.closeFriendMemoriesSwitchLabel = "Add memory with close friend?";

// group memories form //
exports.groupMemoriesFormTitle = "Group Memory";
exports.friendNameFieldLabel = "Friend Name";
// Customizable Area End